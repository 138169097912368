import {useCallback, useEffect, useState} from "react";
import {validatePercentage} from "../../../services/validate";
import {BaSeButton, BaSeGrid, BaSeGridItem, BaSeInput} from "@base/react";
import {Form, Formik} from "formik";
import TitleWithPopover from "../../../components/title-with-popover";
import RouterPrompt from "../../../components/router-prompt";

const popoverRates = {
  direction: 'right',
  alertSize: 180,
  iconProps: {
    color: "#243342",
    size: 16
  },
  title: "% Taxas",
  message: "Taxas percentuais de cada item.",
  type: "popover"
}
const popoverRevenue = {
  direction: 'left',
  alertSize: 180,
  iconProps: {
    color: "#243342",
    size: 16
  },
  title: "% Taxas",
  message: "Taxas percentuais de cada item.",
  type: "popover"
}

const VariableIndirectCosts = ({dataPlanning, sendForm, backStep}) => {
  const structureData = {
    rates: dataPlanning?.rates || '0,00',
    revenue: dataPlanning?.revenue || '0,00',
    rates2: dataPlanning?.rates2 || '0,00',
    revenue2: dataPlanning?.revenue2 || '0,00',
    rates3: dataPlanning?.rates3 || '0,00',
    revenue3: dataPlanning?.revenue3 || '0,00',
    rates4: dataPlanning?.rates4 || '0,00',
    revenue4: dataPlanning?.revenue4 || '0,00',
    rates5: dataPlanning?.rates5 || '0,00',
    revenue5: dataPlanning?.revenue5 || '0,00',
  }
  const [form, setForm] = useState(structureData)
  const [isInvalid, setIsInvalid] = useState(false)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const checkValidForm = useCallback(() => {
    let invalid = false
    if (!invalid) {
      const sumValues = Object.values(form).reduce((acc, value) => {
        const number = typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value;
        return acc + number;
      }, 0);

      invalid = sumValues !== 100
    }
    setIsInvalid(invalid)
  }, [form])
  const submitForm = () => {
    sendForm(form)
  }

  useEffect(() => {
    checkValidForm()
  })

  return (
    <div className="step variable-indirect-costs">
      <TitleWithPopover title="Custos Indiretos Variáveis" messagePopover="Estes são os custos que variam de acordo com as mudanças nas vendas, refletindo o impacto direto das vendas no seu negócio." size={5} />

      <Formik
        initialValues={form}
        onSubmit={() => {
          submitForm()
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <TitleWithPopover title="Taxa de cartão de débito" smallTitle="(média)" />
            <BaSeGrid>
              <BaSeGridItem size={6}>
                <BaSeInput
                  inputMode="decimal"
                  typeInput="tel"
                  prefix="% "
                  maxLength={8}
                  inputStatus={validatePercentage(form.rates) ? "invalid" : "valid"}
                  value={form.rates}
                  onChange={(e) => { setForm({...form, rates: e })}}
                  size="medium"
                  placeholder="%"
                  label="% Taxas"
                  width="100%"
                  mask="decimal"
                  showHelpButton
                  helpButtonProps={popoverRates}
                />
              </BaSeGridItem>
              <BaSeGridItem size={6}>
                <BaSeInput
                  inputMode="decimal"
                  typeInput="tel"
                  prefix="% "
                  maxLength={8}
                  inputStatus={validatePercentage(form.revenue) ? "invalid" : "valid"}
                  value={form.revenue}
                  onChange={(e) => { setForm({...form, revenue: e })}}
                  size="medium"
                  placeholder="%"
                  label="% da Receita Total"
                  width="100%"
                  mask="decimal"
                  showHelpButton
                  helpButtonProps={popoverRevenue}
                />
              </BaSeGridItem>
            </BaSeGrid>

            <TitleWithPopover title="Taxa de cartão de crédito" smallTitle="(média)" />
            <BaSeGrid>
              <BaSeGridItem size={6}>
                <BaSeInput
                  inputMode="decimal"
                  typeInput="tel"
                  prefix="% "
                  maxLength={8}
                  inputStatus={validatePercentage(form.rates2) ? "invalid" : "valid"}
                  value={form.rates2}
                  onChange={(e) => { setForm({...form, rates2: e })}}
                  size="medium"
                  placeholder="%"
                  label="% Taxas"
                  width="100%"
                  mask="decimal"
                  showHelpButton
                  helpButtonProps={popoverRates}
                />
              </BaSeGridItem>
              <BaSeGridItem size={6}>
                <BaSeInput
                  inputMode="decimal"
                  typeInput="tel"
                  prefix="% "
                  maxLength={8}
                  inputStatus={validatePercentage(form.revenue2) ? "invalid" : "valid"}
                  value={form.revenue2}
                  onChange={(e) => { setForm({...form, revenue2: e })}}
                  size="medium"
                  placeholder="%"
                  label="% da Receita Total"
                  width="100%"
                  mask="decimal"
                  showHelpButton
                  helpButtonProps={popoverRevenue}
                />
              </BaSeGridItem>
            </BaSeGrid>

            <TitleWithPopover title="Custos com comissões" />
            <BaSeGrid>
              <BaSeGridItem size={6}>
                <BaSeInput
                  inputMode="decimal"
                  typeInput="tel"
                  prefix="% "
                  maxLength={8}
                  inputStatus={validatePercentage(form.rates3) ? "invalid" : "valid"}
                  value={form.rates3}
                  onChange={(e) => { setForm({...form, rates3: e })}}
                  size="medium"
                  placeholder="%"
                  label="% Taxas"
                  width="100%"
                  mask="decimal"
                  showHelpButton
                  helpButtonProps={popoverRates}
                />
              </BaSeGridItem>
              <BaSeGridItem size={6}>
                <BaSeInput
                  inputMode="decimal"
                  typeInput="tel"
                  prefix="% "
                  maxLength={8}
                  inputStatus={validatePercentage(form.revenue3) ? "invalid" : "valid"}
                  value={form.revenue3}
                  onChange={(e) => { setForm({...form, revenue3: e })}}
                  size="medium"
                  placeholder="%"
                  label="% da Receita Total"
                  width="100%"
                  mask="decimal"
                  showHelpButton
                  helpButtonProps={popoverRevenue}
                />
              </BaSeGridItem>
            </BaSeGrid>

            <TitleWithPopover title="Custos com impostos" />
            <BaSeGrid>
              <BaSeGridItem size={6}>
                <BaSeInput
                  inputMode="decimal"
                  typeInput="tel"
                  prefix="% "
                  maxLength={8}
                  inputStatus={validatePercentage(form.rates4) ? "invalid" : "valid"}
                  value={form.rates4}
                  onChange={(e) => { setForm({...form, rates4: e })}}
                  size="medium"
                  placeholder="%"
                  label="% Taxas"
                  width="100%"
                  mask="decimal"
                  showHelpButton
                  helpButtonProps={popoverRates}
                />
              </BaSeGridItem>
              <BaSeGridItem size={6}>
                <BaSeInput
                  inputMode="decimal"
                  typeInput="tel"
                  prefix="% "
                  maxLength={8}
                  inputStatus={validatePercentage(form.revenue4) ? "invalid" : "valid"}
                  value={form.revenue4}
                  onChange={(e) => { setForm({...form, revenue4: e })}}
                  size="medium"
                  placeholder="%"
                  label="% da Receita Total"
                  width="100%"
                  mask="decimal"
                  showHelpButton
                  helpButtonProps={popoverRevenue}
                />
              </BaSeGridItem>
            </BaSeGrid>

            <TitleWithPopover title="Outros custos" />
            <BaSeGrid>
              <BaSeGridItem size={6}>
                <BaSeInput
                  inputMode="decimal"
                  typeInput="tel"
                  prefix="% "
                  maxLength={8}
                  inputStatus={validatePercentage(form.rates5) ? "invalid" : "valid"}
                  value={form.rates5}
                  onChange={(e) => { setForm({...form, rates5: e })}}
                  size="medium"
                  placeholder="%"
                  label="% Taxas"
                  width="100%"
                  mask="decimal"
                  showHelpButton
                  helpButtonProps={popoverRates}
                />
              </BaSeGridItem>
              <BaSeGridItem size={6}>
                <BaSeInput
                  inputMode="decimal"
                  typeInput="tel"
                  prefix="% "
                  maxLength={8}
                  inputStatus={validatePercentage(form.revenue5) ? "invalid" : "valid"}
                  value={form.revenue5}
                  onChange={(e) => { setForm({...form, revenue5: e })}}
                  size="medium"
                  placeholder="%"
                  label="% da Receita Total"
                  width="100%"
                  mask="decimal"
                  showHelpButton
                  helpButtonProps={popoverRevenue}
                />
              </BaSeGridItem>
            </BaSeGrid>

            <div className="planning__footer">
              <BaSeButton
                type="secondary"
                buttonType="button"
                onClick={() => backStep(hasUnsavedChanges) }
                color="#005eb8"
                size="medium"
                value="Anterior"
                width="87px"
              />

              <BaSeButton
                buttonType="submit"
                size="medium"
                value="Próximo"
                width="87px"
                isDisabled={isInvalid}
                onClick={() => handleSubmit}
              />
            </div>
          </Form>
        )}
      </Formik>

      <RouterPrompt initialValue={structureData} newValue={form} setHasUnsavedChanges={setHasUnsavedChanges} />
    </div>
  )
}

export default VariableIndirectCosts