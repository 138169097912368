import "./index.scss";
import Acronyms from "../acronyms";
import { BaSeHeading4 } from "@base/react";
import { useState } from "react";

const FloatingMenu = ({ data, callBack }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={`profile ${open ? "floating" : ""}`}>
      <div onClick={() => setOpen(!open)}>
        <Acronyms nameUser="Empresa 2" />
      </div>

      <div className="floating-menu">
        <div className="close" onClick={() => setOpen(false)}>
          x
        </div>
        <ul>
          {data.map((item, k) => (
            <li
              key={k}
              onClick={() => {
                if (k > 0) if (callBack) callBack(item.id);
              }}
            >
              <>
                <Acronyms nameUser={`Empresa ${k + 1}`} />
                <div key={k} className="title">
                  <BaSeHeading4>
                    <span>{item.name}</span>
                  </BaSeHeading4>
                  <p>CNPJ: {item.cnpj}</p>
                  <p>Criada em: {item.date}</p>
                </div>
              </>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FloatingMenu;
