import Header from "./header";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import Onboarding from "../onboarding";

export default function Layout({children, ...props}) {
  const { pathname } = useLocation();
  const [showOnboarding, setShowOnboarding] = useState(false)
  const {account} = {...props}

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, [pathname]);
  useEffect(() => {
    setShowOnboarding(!!account)
  }, [account])
  useEffect(() => {
    if (showOnboarding) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
    }
  }, [showOnboarding]);
  return (
    <div className="app">
      {
        account ? (<Header {...props}/>) : ""
      }
      <main>
        {
          showOnboarding ? (<Onboarding setHideOnboarding={() => { setShowOnboarding(false) }} />) : ""
        }

        {children}
      </main>
    </div>
  )
}