import logo from "../../assets/images/logo.svg";
import step1 from "../../assets/images/onboarding/step-1.png"
import step2 from "../../assets/images/onboarding/step-2.png"
import step3 from "../../assets/images/onboarding/step-3.png"
import step4 from "../../assets/images/onboarding/step-4.png"
import {useState} from "react";
import {BaSeButton} from "@base/react";
import './index.scss'

const Onboarding = ({setHideOnboarding}) => {
    const [currentStep, setCurrentStep] = useState(1)
    const contentSteps = [
        {
            img: step1,
            title: "Calcule o Planejamento Financeiro da sua empresa de forma fácil e rápida!",
            subtitle: "Preencha as informações financeiras da sua empresa como vendas, movimentações bancárias, etc."
        },
        {
            img: step2,
            title: null,
            subtitle: `No <strong>Fluxo Projetado</strong>, insira o saldo em caixa, investimentos, financiamentos e dívidas.`
        },
         {
            img: step3,
            title: null,
            subtitle: `E visualize os <strong>Resultados</strong> com indicadores como rentabilidade e lucratividade baseado nas informações preenchidas.`
        }
        ,  {
            img: step4,
            title: null,
            subtitle: "Com isso, você estará pronto (a) para formalizar a sua empresa e/ou obter crédito para o seu negócio!"
        }
    ]

    const navigateTemplate = () => (
        currentStep >= 1 && currentStep < 4 ? (
            <BaSeButton
                buttonType="button"
                onClick={() => {setCurrentStep(currentStep+1)}}
                size="medium"
                value="Próximo"
                width="145px"
            />
        ) : (
            <BaSeButton
                buttonType="button"
                onClick={() => { setHideOnboarding(true) }}
                size="medium"
                value="Vamos lá!"
                width="145px"
            />
        )
    )
    return (
        <div className="onboarding">
            <div className="container">
                <div className="onboarding__header">
                    <img src={logo} className="logo" alt="logo" />
                    <button className="btn-without-style onboarding__skip" onClick={() => { setHideOnboarding(true) }}>
                        Pular >
                    </button>
                </div>
                <div className="steps">
                    {
                        contentSteps.map((item, i) => {
                            let count = i+1

                            return count === currentStep ? (<div key={count} className="step">
                                <figure>
                                    <img src={item.img} alt={`step-${count}`} loading="lazy" />
                                </figure>
                                <div className="text">
                                    <p className="pages-desktop">{currentStep}/{contentSteps.length}</p>
                                    {
                                        item.title ? (<h2 dangerouslySetInnerHTML={{__html: item.title}}/>) : ""
                                    }
                                    {
                                        item.subtitle ? (
                                            <p dangerouslySetInnerHTML={{__html: item.subtitle}}/>) : ""
                                    }
                                    <div className="navigate-desktop">
                                        {navigateTemplate()}
                                        {
                                            currentStep < 4 ?
                                                (<button className="btn-without-style onboarding__skip"
                                                         onClick={() => { setHideOnboarding(true) }}>
                                                    Pular >
                                                </button>)
                                                : ""
                                        }
                                    </div>
                                </div>
                            </div>) : ""
                        })
                    }
                </div>
                <div className="onboarding__footer">
                    <p>{currentStep}/{contentSteps.length}</p>
                    {navigateTemplate()}
                </div>
            </div>
        </div>
    )
}

export default Onboarding