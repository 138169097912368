import "./index.scss"

const ProgressBar = ({max = 100, value = 0}) => {
  return (
    <div className="custom-progress-bar">
      <div className="progress-bar">
        <div className="progress-bar__bar">
          <p style={{width: `${value}%`}}>{value}%</p>
          <progress max={max} value={value}/>
        </div>
        <div className="progress-bar__max">
          {max}%
        </div>
      </div>
      <p>
        A soma dos percentuais lançados <strong>DEVE SER IGUAL a {max}%</strong>.
      </p>
    </div>
  )
}

export default ProgressBar