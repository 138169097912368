import { BaSeHeading1 } from "@base/react";
import "./index.scss";
import { useEffect, useState } from "react";

import Acronyms from "../../components/acronyms";
import { BaSeButton, BaSeCheckbox } from "@base/react";
import { BaSeHeading3, BaSeParagraph, BaSeSmall2 } from "@base/react";
import { Companies } from "../../mocks/menu";

const Home = ({ account, setAccount }) => {
  const { name } = account;

  const handleRegisterCompany = () => {
    window.location.href = "https://cpe.sebrae.com.br/";
  };

  const [companiesList] = useState(Companies);
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [selectedAgree, setSelectedAgree] = useState(false);

  useEffect(() => {
    setSelectedRadio(selectedRadio);
  }, [selectedRadio]);

  const handleCheckboxAgree = (checked) => {
    setSelectedAgree(checked);
  };

  const createNewPlanning = () => {
    alert("Clicado");
  };

  return (
    <div>
      {companiesList.length > 0 ? (
        <div className="related-companies-container">
          <BaSeHeading3 color="#005EB8" fontFamily="Alegreya Sans" isBold>
            Olá empresário!
          </BaSeHeading3>
          <BaSeParagraph className="top-text">
            Essas são as empresas vinculadas ao seu CPF. Selecione uma para
            começar a utilizar a calculadora:
          </BaSeParagraph>
          <div className="available-companies">
            {companiesList.map((company, k) => (
              <div
                className={`company__radio ${
                  selectedRadio === company.id ? "selected" : ""
                }`}
                key={company.id}
                onClick={() => setSelectedRadio(company.id)}
              >
                <div className="company__radio__data">
                  <Acronyms nameUser={`E ${k + 1}`} />
                  <div className="company__radio__data__text">
                    <BaSeParagraph isBold color="#005EB8">
                      {company.name}
                    </BaSeParagraph>
                    <BaSeSmall2 color="#617385">
                      CNPJ: {company.cnpj}
                    </BaSeSmall2>
                    <BaSeSmall2 color="#617385">
                      Criada em: {company.date}
                    </BaSeSmall2>
                  </div>
                </div>
                <div className="company__radio__checkbox-comp">
                  <BaSeCheckbox
                    shape="circle"
                    key={company.id}
                    id={`company_radio_${company.id}`}
                    name="companies"
                    value={company.id}
                    checked={selectedRadio === company.id}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="company__bottom__content">
            <div className="read-terms">
              <BaSeCheckbox
                label={
                  <span>
                    Declaro que li e concordo com os{" "}
                    <a href="" className="underlined-term" target="_blank">
                      Termos de Uso
                    </a>{" "}
                    e a{" "}
                    <a href="" className="underlined-term" target="_blank">
                      Política de Privacidade
                    </a>
                    .
                  </span>
                }
                id="company_read_and_agree"
                name="read_and_agree"
                checked={selectedAgree}
                onChange={handleCheckboxAgree}
              />
            </div>
            <div className="company__button">
              <BaSeButton
                isDisabled={!selectedRadio || !selectedAgree}
                onClick={createNewPlanning}
                value="Novo planejamento"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="related-companies-container">
          <BaSeHeading3 color="#005EB8" fontFamily="Alegreya Sans" isBold>
            Olá empresário!
          </BaSeHeading3>
          <BaSeParagraph className="top-text">
            Não identificamos empresas vinculadas ao seu CPF. Cadastre a sua
            empresa ou continue com o seu CPF.
          </BaSeParagraph>
          <div className="company__register__area">
            <div className="company__register__area__button">
              <BaSeButton
                value="Cadastrar minha empresa"
                onClick={handleRegisterCompany}
              />
            </div>
            <div className="company__register__area__motto">
              <div className="line" />
              <BaSeParagraph className="top-text">ou</BaSeParagraph>
              <div className="line" />
            </div>
            <a>Continuar com o meu CPF</a>
          </div>
          <div className="company__bottom__content">
            <div className="read-terms">
              <BaSeCheckbox
                label={
                  <span>
                    Declaro que li e concordo com os{" "}
                    <a href="" className="underlined-term" target="_blank">
                      Termos de Uso
                    </a>{" "}
                    e a{" "}
                    <a href="" className="underlined-term" target="_blank">
                      Política de Privacidade
                    </a>
                    .
                  </span>
                }
                id="company_read_and_agree"
                name="read_and_agree"
                checked={selectedAgree}
                onChange={handleCheckboxAgree}
              />
            </div>
            <div className="company__button">
              <BaSeButton
                isDisabled={!selectedAgree}
                onClick={createNewPlanning}
                value="Novo planejamento"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
