import {BaSeButton, BaSeGrid, BaSeGridItem, BaSeIcon} from "@base/react";
import {useCallback, useEffect, useState} from "react";
import {Form, Formik} from "formik";
import TitleWithPopover from "../../../components/title-with-popover";
import RouterPrompt from "../../../components/router-prompt";
import InputRange from "../../../components/input-range";
import ProgressBar from "../../../components/progress-bar";
import {sumValuesPercent} from "../../../services/utils";

const ReceiptDeadline = ({dataPlanning, sendForm, backStep}) => {
  const structureData = {
    in_sight: dataPlanning?.in_sight || 0,
    days: dataPlanning?.days || 0,
    plot2: dataPlanning?.plot2 || 0,
    plot3: dataPlanning?.plot3 || 0,
    plot4: dataPlanning?.plot4 || 0,
    plot5: dataPlanning?.plot5 || 0,
  }
  const [form, setForm] = useState(structureData)
  const [isInvalid, setIsInvalid] = useState(false)

  const checkValidForm = useCallback(() => {
    let invalid = false
    if (!invalid) {
      invalid = sumValuesPercent(form) !== 100
    }
    setIsInvalid(invalid)
  }, [form])

  const submitForm = () => {
    sendForm(form)
  }

  useEffect(() => {
    checkValidForm()
  })

  return (
    <div className="step receipt-deadline">
      <TitleWithPopover title="Prazos de Recebimento"
                        titlePopover="% da Receita"
                        messagePopover="A soma dos percentuais lançados DEVE SER IGUAL a 100%." size={5} />

      <Formik
        initialValues={form}
        onSubmit={() => {
          submitForm()
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="group-input desktop">
              <BaSeGrid>
                <BaSeGridItem desktop={{
                  size: 6,
                  startAt: undefined
                }}>
                  <div className="group-input mobile">
                    <InputRange
                      label="Recebimento à vista"
                      value={form.in_sight}
                      setValue={(e) => {
                        setForm({...form, in_sight: e})
                      }}/>
                  </div>
                </BaSeGridItem>
                <BaSeGridItem desktop={{
                  size: 6,
                  startAt: undefined
                }}>
                  <div className="group-input mobile">
                    <InputRange
                      label="Recebimento em 30 dias"
                      value={form.days}
                      setValue={(e) => {
                        setForm({...form, days: e})
                      }}/>
                  </div>
                </BaSeGridItem>
              </BaSeGrid>
            </div>

            <div className="installments">
              <BaSeGrid>
                <BaSeGridItem desktop={{
                  size: 6,
                  startAt: undefined
                }}>
                  <InputRange
                    label="Recebimento em 2x"
                    value={form.plot2}
                    setValue={(e) => {
                      setForm({...form, plot2: e})
                    }}/>
                </BaSeGridItem>
                <BaSeGridItem desktop={{
                  size: 6,
                  startAt: undefined
                }}>
                  <InputRange
                    label="Recebimento em 3x"
                    value={form.plot3}
                    setValue={(e) => {
                      setForm({...form, plot3: e})
                    }}/>
                </BaSeGridItem>
              </BaSeGrid>

              <button type="button" className="btn-without-style btn-add-more">
                <BaSeIcon
                  color="#005EB8"
                  description="Adicionar"
                  name="plus-square"
                  size={24}
                />
              </button>
            </div>

            <ProgressBar value={sumValuesPercent(form) > 100 ? 100 : sumValuesPercent(form)} />

            <div className="wrapper-button-secondary">
              <BaSeButton
                buttonType="button"
                color="#005EB8"
                isBold
                onClick={() => {console.log("save")}}
                rightIcon="save"
                size="small"
                type="secondary"
                value="Salvar"
              />
            </div>

            <div className="planning__footer">
              <BaSeButton
                type="secondary"
                buttonType="button"
                onClick={() => backStep()}
                color="#005eb8"
                size="medium"
                value="Anterior"
                width="87px"
              />

              <BaSeButton
                buttonType="submit"
                size="medium"
                value="Próximo"
                width="87px"
                isDisabled={isInvalid}
                onClick={() => handleSubmit}
              />
            </div>
          </Form>
        )}
      </Formik>

      <RouterPrompt initialValue={structureData} newValue={form} />
    </div>
  )
}

export default ReceiptDeadline