import "./index.scss";
import { BaSeHeading5, BaSeIcon } from "@base/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Loading from "../loading";

const CardStatus = ({ data, idItem, callback }) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(false);

  useEffect(() => {
    setId(idItem);
  }, [idItem]);

  return (
    <div className={`card-status ${data.isFinished ? "completed" : ""}`}>
      <div
        className="content"
        onClick={() => {
          if (open) setOpen(false);
        }}
      >
        <div className="title">
          <BaSeHeading5>{data.title}</BaSeHeading5>
          <p>
            ({data.isFinished ? "Concluído" : "Em andamento"} • Última edição:{" "}
            {moment(data.date).format("DD/MM/YYYY")})
          </p>
        </div>

        {id === data.id ? <Loading small={true} /> : null}
        <button
          className="open-options"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <BaSeIcon
            color="#005EB8"
            description="Menu"
            name="system/more-2-fill"
            size={24}
          />
        </button>
      </div>

      {id !== data.id ? (
        <div className={`options ${open ? "open" : ""}`}>
          <ul>
            <li>
              <BaSeIcon
                color="#005EB8"
                description="Menu"
                name="eye"
                size={24}
              />
              <Link to={`/visializar/${data.id}`}>Visualizar</Link>
            </li>
            <li>
              <BaSeIcon
                color="#005EB8"
                description="Menu"
                name="design/edit-2-line"
                size={24}
              />
              <Link to={`/editar/${data.id}`}>Editar</Link>
            </li>
            <li>
              <BaSeIcon
                color="#005EB8"
                description="Menu"
                name="copy"
                size={24}
              />
              <button
                type="button"
                onClick={() => {
                  if (callback) callback({ id: data.id, type: "duplicate" });
                }}
              >
                Duplicar
              </button>
            </li>
            <li>
              <BaSeIcon
                color="#005EB8"
                description="Menu"
                name="system/delete-bin-line"
                size={24}
              />
              <button
                type="button"
                onClick={() => {
                  if (callback) callback({ id: data.id, type: "delete" });
                  setOpen(false);
                }}
              >
                Excluir
              </button>
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default CardStatus;
