import {convertToSlug} from "../../services/utils";
import "./index.scss"

const InputRange = ({
  label = "",
                      id = convertToSlug(label) || "customRange", name,
                      value = 0, setValue,
                      min = 0, max = 100
                    }) => {

  const styleLabel = (value) => {
    value = parseInt(value)
    let css = {
      left: `${value}%`,
      transform: "translateX(0)"
    }
    if (value >= 2 && value <= 9) {
      css.transform = "translateX(-3px)"
    }
    if (value >= 10 && value <= 49) {
      css.transform = "translateX(-6px)"
    }
    if (value >= 50 && value <= 79) {
      css.transform = "translateX(-11px)"
    }
    if (value >= 80 && value <= 99) {
      css.transform = "translateX(-17px)"
    }
    if (value === 100) {
      css.transform = "translateX(-22px)"
    }

    return css
  }

  return (
    <div className={"custom-input-range" + (value > min ? " has-value" : "")}>
      <div className="input-range">
        <label htmlFor={id} className="form-label">{label}</label>
        <div className="input-range__wrapper-input">
          <span className="label-value" style={ styleLabel(value) }>{value}%</span>
          <input type="range"
                 data-value={value}
                 name={name}
                 value={value}
                 onChange={(e) => setValue(e.target.value)}
                 min={min} max={max}
                 id={id}/>
          <small>{min}%</small>
          <small>{max}%</small>
        </div>
      </div>
    </div>
  )
}

export default InputRange