import "./index.scss";
import {
  BaSeButton,
  BaSeHeading4,
  BaSeHeading6,
  BaSeParagraph,
} from "@base/react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Loading from "../loading";
import ApiCMS from "../../services/cms";
import logoIdea from "../../assets/images/logoIdea.svg";
import arrowDown from "../../assets/images/icons/yellow-down-arrow.svg";

const ApiPills = ApiCMS();

const BoxPillPlanning = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [pills, setPills] = useState([]);
  const [loading, setLoading] = useState(false);

  const howToUseAccordion = () => {
    setIsOpen(!isOpen);
  };

  const getPills = () => {
    ApiPills.getAllPills(1, 3)
      .then((response) => {
        const { data } = response;
        setPills(data.results);
      })
      .catch((error) => {
        console.warn("ERROR: ", error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getPills();
    setLoading(true);
  }, []);

  return (
    <div className="box-pill-planning">
      <div className="pill-planning-left">
        {children}
        <div className="pill-planning-left__container">
          <div className="block-how-to-use">
            <div className="block-how-to-use__box">
              <img src={logoIdea} />
              <BaSeHeading6>
                Relembre o passo-a-passo de como utilizar
              </BaSeHeading6>
            </div>
            <div
              className="block-how-to-use__accordion"
              onClick={howToUseAccordion}
            >
              <BaSeHeading6 isBold>
                Como utilizar a Planejadora Sebrae?
              </BaSeHeading6>
              <img
                src={arrowDown}
                style={{
                  transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.3s",
                }}
              />
            </div>

            <div
              className={`block-how-to-use__hidden-content ${
                isOpen ? "open" : ""
              }`}
            >
              <ul>
                <li>
                  A primeira coisa a fazer é preencher as informações
                  financeiras da sua empresa na guia de DADOS. Busque em seus
                  controles financeiros, como registros de venda, movimentações
                  bancárias etc. É indispensável preencher todos os campos
                  solicitados.
                </li>
                <li>
                  Em seguida, vá para a guia de FLUXO PROJETADO e lance o saldo
                  em caixa da empresa, investimentos, financiamentos e dívidas
                  parceladas da empresa.
                </li>
                <li>
                  Com todas as informações devidamente inseridas, você poderá
                  avaliar, na guia RESULTADO, a Rentabilidade calculada,
                  Lucratividade e outros indicadores financeiros.
                </li>
                <li>
                  Utilize a sessão GARANTIAS para avaliar o quanto de garantia
                  real você necessita para realizar uma operação de crédito com
                  uso do FAMPE.
                </li>
              </ul>
              <p>
                Você encontrará uma nota explicativa complementar com conceitos
                e exemplos, no canto superior direito de algumas células.
              </p>
            </div>

            <BaSeButton value="Novo planejamento" />
          </div>
          <div className="line" />
        </div>
      </div>
      <div className="pill-planning-right">
        <div className="pill-planning-right__mobile">
          <BaSeHeading4 isBold>Pílulas de Conhecimento</BaSeHeading4>
        </div>
        <div className="pill-planning-right__desktop">
          <BaSeHeading6 isBold>Conteúdos</BaSeHeading6>
        </div>
        <BaSeHeading6 isBold>Lorem Ipsum</BaSeHeading6>
        <div className="pill-planning-right__content">
          {loading ? (
            <Loading />
          ) : (
            <div className="pills-cards">
              {pills.length &&
                pills.slice(0, 3).map((e) => (
                  <Link to={`/pilula/${e.id}`} key={e.id}>
                    {e.image ? (
                      <img
                        src={e.image}
                        width="286px"
                        height="184px"
                        alt="Imagem em destaque"
                        className="image"
                      />
                    ) : (
                      <iframe
                        src={e.video}
                        width="465px"
                        height="261.95px"
                        className="video"
                        style={{ pointerEvents: "none" }}
                      />
                    )}
                  </Link>
                ))}
              <Link className="see-all" to="/todas-pilulas-de-conhecimento">
                <BaSeParagraph color="#006BFF">Ver todos</BaSeParagraph>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BoxPillPlanning;
