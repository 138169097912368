import "./index.scss"
import {BaSeButton, BaSeIcon} from "@base/react";

const Dialog = ({show, onHide,
                  title, description,
                  callbackConfirm, callbackCancel,
                  btnCancel = "Cancelar", btnConfirm = "Confirmar",
                  maxWidth = "405px", showClose = true, showBtnCancel = true}) => {
  const fnCancel = () => {
    callbackCancel()
    onHide()
  }
  const fnConfirm = () => {
    callbackConfirm()
    onHide()
  }

  return (
    show ? (<div className="dialog">
      <div className="dialog__overlay" onClick={() => onHide()}></div>
      <div className="dialog__content" style={{maxWidth: maxWidth}}>
        <div className="dialog__header">
          {
            title ? (
              <h4>{title}</h4>
            ) : ""
          }
          {
            showClose ? (
              <button className="btn-without-style" onClick={() => onHide()}>
                <BaSeIcon
                  color="#005EB8"
                  description="Close"
                  name="close"
                  size={22}
                />
              </button>
            ) : ""
          }
        </div>
        <div className="dialog__body">
          {
            description ? (
              <p>{description}</p>
            ) : ""
          }

          <div className="dialog__buttons">
            {
              showBtnCancel ? (<BaSeButton
                type="secondary"
                buttonType="button"
                onClick={() => fnCancel()}
                color="#243342"
                size="small"
                value={btnCancel}
              />) : ""
            }

            <BaSeButton
              buttonType="button"
              size="small"
              value={btnConfirm}
              onClick={() => fnConfirm()}
            />
          </div>
        </div>
      </div>
    </div>) : ""
  )
}

export default Dialog