import React, { useRef, useState } from "react";
import "./index.scss";
import { BaSeIcon } from "@base/react";

function Accordion(props) {
  const [active, setActive] = useState(false);
  const content = useRef(null);
  const [height, setHeight] = useState("0px");

  const toggleAccordion = () => {
    setActive(!active);
    setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
  };

  const action = () => (props.handleClick ? props.handleClick() : null);

  return (
    <>
      <div className="accordion-section">
        <div
          className={`accordion ${active ? "active" : ""}`}
          onClick={() => {
            action();
            toggleAccordion();
          }}
        >
          <span className="arrow">
            <figure
              style={{
                transform: `scale(${active ? "-1" : "1"})`,
                transition: "0.2s",
              }}
            >
              <BaSeIcon
                color="#617385"
                description="Menu"
                name="system/arrow-down-s-line"
                size={24}
              />
            </figure>
          </span>
          <p className="accordion-title">{props.title}</p>
        </div>
        <div
          ref={content}
          style={{ maxHeight: `${height}` }}
          className={`accordion-content ${active ? "active" : ""}`}
        >
          {props.children}
        </div>
      </div>
    </>
  );
}

export default Accordion;
