import './index.scss'

const Acronyms = ({nameUser, background = 'linear-gradient(to right, #3b4aff 0%,#40bbff 100%)'}) => {

    const getAcronyms = () => {
        let names = nameUser.split(" "),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    }

    return (
        <div className="acronyms" style={{background: background}}>
            {getAcronyms()}
        </div>
    )
}

export default Acronyms