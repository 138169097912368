export function validateEmail(value) {
  let error;
  if (!value) {
    error = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'Invalid email address';
  }
  return error;
}

export function validateIsRequired(value) {
  return !value.trim();
}
export function validateCurrency(value) {
  return value <= 0 || !/^\d+(?:\.\d{0,2})$/.test(value);
}
export function validatePercentage(value) {
  if (value) {
    value = parseFloat(value.toString().replace(",", ".").trim())
    return (value < 0 || value > 100) || !/^[+-]?\d+(\.\d+)?$/.test(value);
  }
}
