import {useCallback, useState} from "react";
import NamePlanning from "./steps/name-planning";
import MonthlyAverage from "./steps/monthly-average";
import VariableIndirectCosts from "./steps/variable-indirect-costs";
import RouterPrompt from "../../components/router-prompt";
import "./index.scss"
import TotalInvestment from "./steps/total-investment";
import ReceiptDeadline from "./steps/receipt-deadline";

const Planning = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const [dataPlanning, setDataPlanning] = useState(null)
  const [openDialogPrompt, setOpenDialogPrompt] = useState(false)

  const steps = useCallback(() => {
    const updateDataPlanning = (value) => {
      setDataPlanning({...dataPlanning, ...value})
      setCurrentStep(currentStep+1)
    }
    const fnBackStep = (hasChanges) => {
      checkHasChanges(hasChanges)
      if (!hasChanges) {
        setCurrentStep(currentStep-1)
      }
    }

    return [
      {
        index: 1,
        title: dataPlanning?.name ? dataPlanning?.name : "Novo Planejamento",
        content: <NamePlanning dataPlanning={dataPlanning} sendForm={(e) => { updateDataPlanning(e) }} />
      },
      {
        index: 2,
        title: "Média Mensal",
        content: <MonthlyAverage dataPlanning={dataPlanning} backStep={() => fnBackStep(false)} sendForm={(e) => { updateDataPlanning(e) }} />
      },
      {
        index: 3,
        title: "Custos Indiretos Variáveis",
        content: <VariableIndirectCosts dataPlanning={dataPlanning} backStep={(hasChanges) => fnBackStep(hasChanges)} sendForm={(e) => { updateDataPlanning(e) }} />
      },
      {
        index: 4,
        title: "Investimento Total",
        content: <TotalInvestment dataPlanning={dataPlanning} backStep={() => fnBackStep(false)} sendForm={(e) => { updateDataPlanning(e) }} />
      },
      {
        index: 5,
        title: "Prazos de Recebimento",
        content: <ReceiptDeadline dataPlanning={dataPlanning} backStep={(hasChanges) => fnBackStep(hasChanges)} sendForm={(e) => { updateDataPlanning(e) }} />
      },
      {
        index: 6,
        title: "Prazos de Pagamento",
        content: ""
      },
      {
        index: 7,
        title: "Giro de Estoque",
        content: ""
      },
      {
        index: 8,
        title: "Resultados",
        content: ""
      },
      {
        index: 9,
        title: "Fluxo de Caixa Projetado",
        content: ""
      },
      {
        index: 10,
        title: "Garantias",
        content: ""
      }
    ]
  }, [dataPlanning, currentStep])

  const checkHasChanges = (hasChanges) => {
    setOpenDialogPrompt(hasChanges)
  }

  return (
    <div className="planning">
      <div className="steps">
        <div className="steps__header">
          <ul>
            {
              steps().map((step) => {
                const active = step.index === currentStep ? "active" : ""
                const hasName = step.index === 1 && currentStep > 1 ? "has-name" : ""
                const isCompleted = step.index < currentStep ? "is-completed" : ""
                return (
                  <li key={step.index} className={`${active} ${hasName} ${isCompleted}`}>
                    <p>{ step.title }</p>
                  </li>
                )
              })
            }
          </ul>
        </div>
        <div className="steps__body">
          {
            steps().map((step) => (
              step.index === currentStep ? (<div className="container" key={step.index}>
                {step.content}
              </div>) : ""
            ))
          }
        </div>
      </div>

      <RouterPrompt callbackCancel={() => setOpenDialogPrompt(false)} callbackConfirm={() => setCurrentStep(currentStep-1)} forceShowDialog={openDialogPrompt} />
    </div>
  )
}

export default Planning