import {validateCurrency} from "../../../services/validate";
import {BaSeButton, BaSeGrid, BaSeGridItem, BaSeHeading5, BaSeInput} from "@base/react";
import {useCallback, useEffect, useState} from "react";
import {Form, Formik} from "formik";
import TitleWithPopover from "../../../components/title-with-popover";

const MonthlyAverage = ({dataPlanning, sendForm, backStep}) => {
  const [form, setForm] = useState({
    products: dataPlanning?.products || 0,
    services: dataPlanning?.services || 0,
    products2: dataPlanning?.products2 || 0,
    services2: dataPlanning?.services2 || 0,
    value: dataPlanning?.value || 0,
  })
  const [isInvalid, setIsInvalid] = useState(false)

  const checkValidForm = useCallback(() => {
    let invalid = false
    if (!invalid) {
      // eslint-disable-next-line no-unused-vars
      for (const [k, value] of Object.entries(form)) {
        invalid = validateCurrency(value)
      }
    }
    setIsInvalid(invalid)
  }, [form])
  const submitForm = () => {
    sendForm(form)
  }

  useEffect(() => {
    checkValidForm()
  })

  return (
    <div className="step monthly-average">
      <BaSeHeading5 color="#005EB8" fontFamily="Alegreya Sans" isBold className="mt-0">
        Média Mensal
      </BaSeHeading5>

      <Formik
        initialValues={form}
        onSubmit={() => {
          submitForm()
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <TitleWithPopover title="Receita total" messagePopover="Para negócios em funcionamento, insira o valor médio mensal das vendas dos últimos três meses. Se você está planejando um novo negócio, informe o valor da meta estabelecida para a receita mensal." />
            <BaSeGrid>
              <BaSeGridItem size={6}>
                <BaSeInput
                  color="default"
                  inputMode="decimal"
                  typeInput="tel"
                  inputStatus={validateCurrency(form.products) ? "invalid" : "valid"}
                  value={form.products}
                  onChange={(e) => { setForm({...form, products: e })}}
                  placeholder="R$"
                  mask="currency"
                  size="medium"
                  label="Produtos"
                  width="100%"
                />
              </BaSeGridItem>
              <BaSeGridItem size={6}>
                <BaSeInput
                  color="default"
                  inputMode="decimal"
                  typeInput="tel"
                  inputStatus={validateCurrency(form.services) ? "invalid" : "valid"}
                  value={form.services}
                  onChange={(e) => { setForm({...form, services: e })}}
                  placeholder="R$"
                  mask="currency"
                  size="medium"
                  label="Serviços"
                  width="100%"
                />
              </BaSeGridItem>
            </BaSeGrid>

            <TitleWithPopover title="Custo com mercadorias" messagePopover="Para negócios em funcionamento, informe o valor médio dos custos dos últimos três meses. Caso esteja planejando um novo negócio, insira o custo das mercadorias de acordo com a meta de vendas mensais estabelecida." />
            <BaSeGrid>
              <BaSeGridItem size={6}>
                <BaSeInput
                  color="default"
                  inputMode="decimal"
                  typeInput="tel"
                  inputStatus={validateCurrency(form.products2) ? "invalid" : "valid"}
                  value={form.products2}
                  onChange={(e) => { setForm({...form, products2: e })}}
                  placeholder="R$"
                  mask="currency"
                  size="medium"
                  label="Produtos"
                  width="100%"
                />
              </BaSeGridItem>
              <BaSeGridItem size={6}>
                <BaSeInput
                  color="default"
                  inputMode="decimal"
                  typeInput="tel"
                  inputStatus={validateCurrency(form.services2) ? "invalid" : "valid"}
                  value={form.services2}
                  onChange={(e) => { setForm({...form, services2: e })}}
                  placeholder="R$"
                  mask="currency"
                  size="medium"
                  label="Serviços"
                  width="100%"
                />
              </BaSeGridItem>
            </BaSeGrid>

            <TitleWithPopover title="Custos Indiretos Fixos" messagePopover="Informe a soma de todos os custos fixos mensais, como aluguel e outras despesas recorrentes." />
            <BaSeGrid>
              <BaSeGridItem size={6}>
                <BaSeInput
                  color="default"
                  inputMode="decimal"
                  typeInput="tel"
                  inputStatus={validateCurrency(form.value) ? "invalid" : "valid"}
                  value={form.value}
                  onChange={(e) => { setForm({...form, value: e })}}
                  placeholder="R$"
                  mask="currency"
                  size="medium"
                  label="Produtos"
                  width="100%"
                />
              </BaSeGridItem>
            </BaSeGrid>

            <div className="planning__footer">
              <BaSeButton
                type="secondary"
                buttonType="button"
                onClick={() => backStep() }
                color="#005eb8"
                size="medium"
                value="Anterior"
                width="87px"
              />

              <BaSeButton
                buttonType="submit"
                size="medium"
                value="Próximo"
                width="87px"
                isDisabled={isInvalid}
                onClick={() => handleSubmit}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default MonthlyAverage