import "./index.scss";
import {
  BaSeHeading3,
  BaSeHeading4,
  BaSeHeading5,
  BaSeParagraph,
} from "@base/react";
import BoxPillPlanning from "../../components/box-pill-planning";
import { useState } from "react";

const PillPlanning = () => {
  const [blocks] = useState([
    {
      id: "ID-4A7F9C3E",
      content: "Lucro",
      name: "lucro",
    },
    {
      id: "ID-B8E7D2A9",
      content: "Lucratividade",
      name: "lucratividade",
    },
    {
      id: "ID-C9D4E5B6",
      content: "Rentabilidade",
      name: "rentabilidade",
    },
    {
      id: "ID-5F8A6C1D",
      content: "Prazo de Retorno do Investimento",
      name: "prazo",
    },
    {
      id: "ID-7E3B9F2A",
      content: "Necessidade de formação de Capital de Giro",
      name: "necessidade",
    },
  ]);

  return (
    <div className="company-pill-area">
      <BoxPillPlanning>
        <BaSeHeading3 color="#005EB8" fontFamily="Alegreya Sans" isBold>
          Empresa 2
        </BaSeHeading3>
        <span className="h4-pad">
          <BaSeHeading4 isBold>A Planejadora Sebrae</BaSeHeading4>
        </span>
        <BaSeParagraph>
          A Planejadora Financeira Empresarial Sebrae com foco em crédito foi
          especialmente estruturada para ajudar você, que deseja obter crédito
          para o seu negócio.
        </BaSeParagraph>
        <span className="h4-pad">
          <BaSeParagraph>
            Ou mesmo para você, empreendedor, que está iniciando a jornada
            empresarial e necessita de investimento para formalizar a sua
            empresa e colocar o seu plano de negócios para rodar.
          </BaSeParagraph>
        </span>
        <BaSeHeading5 isBold>O que posso calcular?</BaSeHeading5>
        <div className="blocks-area">
          {blocks.map((e, index) => (
            <div key={e.id || index} className={`block ${e.name}`}>
              <BaSeParagraph isBold>{e.content}</BaSeParagraph>
            </div>
          ))}
        </div>
      </BoxPillPlanning>
    </div>
  );
};

export default PillPlanning;
