import {validateCurrency} from "../../../services/validate";
import {BaSeButton, BaSeHeading5, BaSeInput} from "@base/react";
import {useCallback, useEffect, useState} from "react";
import {Form, Formik} from "formik";

const TotalInvestment = ({dataPlanning, sendForm, backStep}) => {
  const [form, setForm] = useState({
    fixed: dataPlanning?.fixed || 0,
    own: dataPlanning?.own || 0,
  })
  const [isInvalid, setIsInvalid] = useState(false)

  const checkValidForm = useCallback(() => {
    let invalid = false
    if (!invalid) {
      // eslint-disable-next-line no-unused-vars
      for (const [k, value] of Object.entries(form)) {
        invalid = validateCurrency(value)
      }
    }
    setIsInvalid(invalid)
  }, [form])
  const submitForm = () => {
    sendForm(form)
  }

  useEffect(() => {
    checkValidForm()
  })

  return (
    <div className="step total-investment">
      <BaSeHeading5 color="#005EB8" fontFamily="Alegreya Sans" isBold className="mt-0">
        Investimento total
      </BaSeHeading5>

      <Formik
        initialValues={form}
        onSubmit={() => {
          submitForm()
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <BaSeInput
              color="default"
              inputMode="decimal"
              typeInput="tel"
              inputStatus={validateCurrency(form.fixed) ? "invalid" : "valid"}
              value={form.fixed}
              onChange={(e) => { setForm({...form, fixed: e })}}
              placeholder="R$"
              mask="currency"
              size="medium"
              label="Valor investimento fixo"
              width="100%"
              showHelpButton
              helpButtonProps={{
                direction: 'top',
                alertSize: 180,
                iconProps: {
                  color: "#243342",
                  size: 16
                },
                title: "Valor investimento fixo",
                message: "Informe o valor dos móveis, equipamentos, máquinas e instalações. Para novos\n" +
                  "negócios, insira o valor do investimento necessário para iniciar as operações e alcançar as metas de vendas mensais.",
                type: "popover"
              }}
            />

            <BaSeInput
              color="default"
              inputMode="decimal"
              typeInput="tel"
              inputStatus={validateCurrency(form.own) ? "invalid" : "valid"}
              value={form.own}
              onChange={(e) => { setForm({...form, own: e })}}
              placeholder="R$"
              mask="currency"
              size="medium"
              label="Valor Capital de Giro próprio "
              width="100%"
              showHelpButton
              helpButtonProps={{
                direction: 'top',
                alertSize: 180,
                iconProps: {
                  color: "#243342",
                  size: 16
                },
                title: "Valor Capital de Giro próprio ",
                message: "Informe o valor total disponível que a empresa possui para cobrir pagamentos e custos operacionais em momentos de baixa no caixa. Este é o investimento financeiro da empresa.",
                type: "popover"
              }}
            />

            <div className="planning__footer">
              <BaSeButton
                type="secondary"
                buttonType="button"
                onClick={() => backStep() }
                color="#005eb8"
                size="medium"
                value="Anterior"
                width="87px"
              />

              <BaSeButton
                buttonType="submit"
                size="medium"
                value="Próximo"
                width="87px"
                isDisabled={isInvalid}
                onClick={() => handleSubmit}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default TotalInvestment