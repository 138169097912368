import { useEffect, useState } from "react";
import {
  BaSeDatePicker,
  BaSeHeading3,
  BaSeHeading5,
  BaSeIcon,
  BaSeInput,
} from "@base/react";
import "./index.scss";
import CardStatus from "../../components/card-status";
import Accordion from "../../components/accordion";
import BoxPillPlanning from "../../components/box-pill-planning";
import ApiPlannings from "../../services/planning";
import Loading from "../../components/loading";
import CardTrash from "../../components/card-trash";
import { converterSnakeToCamelCase } from "../../services/utils";
const apiPlannings = ApiPlannings();

const count = 4;
const StepComponent = ({ filterActive, filterType }) => {
  const steps = [
    {
      label: "Todos",
      action: "clear",
    },
    {
      label: "Concluídos",
      action: "progress",
    },
    {
      label: "Em andamento",
      action: "completed",
    },
  ];
  return (
    <div className="step">
      {steps.map((step, index) => (
        <button
          key={index}
          type="button"
          className={filterActive === index ? "active" : ""}
          onClick={() => filterType(step.action, index)}
        >
          {step.label}
        </button>
      ))}
    </div>
  );
};

const MyPlanning = ({ account }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState("");
  const [listPlanning, setListPlanning] = useState([]);
  const [list, setList] = useState([]);
  const [listTrash, setListTrash] = useState([]);
  const [filterActive, setFilterActive] = useState(0);
  const [planningToShow, setPlanningToShow] = useState(count);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [close, setClose] = useState("");

  const filterType = (type, active) => {
    setFilterActive(active);

    if (type === "clear") {
      setListPlanning(list);
    } else {
      const listFilter = list.filter((item) =>
        type === "progress" ? item.isFinished : !item.isFinished,
      );
      setListPlanning(listFilter);
    }

    setPlanningToShow(count);
  };

  const getPlannings = () => {
    apiPlannings
      .getPlanning()
      .then((response) => {
        const { data } = response;

        const [actualList, trashList] = data.reduce(
          ([actual, trash], item) => {
            item.in_trashcan
              ? trash.push(converterSnakeToCamelCase(item))
              : actual.push(converterSnakeToCamelCase(item));
            return [actual, trash];
          },
          [[], []],
        );

        setListPlanning(actualList);
        setList(actualList);
        setListTrash(trashList);
      })
      .catch((err) => {
        console.warn("ERROR => ", err);
      })
      .finally(() => {
        setId("");
        setLoading(false);
      });
  };

  const deletePlanning = (id) => {
    setId(id);
    apiPlannings
      .deletePlanning(id)
      .then(() => {
        getPlannings();
      })
      .catch((err) => console.log(err));
  };

  const restorePlanning = (id) => {
    setId(id);
    apiPlannings
      .updatePlanning({ in_trashcan: false }, id)
      .then(() => {
        getPlannings();
      })
      .catch((err) => console.log(err));
  };

  const filteredData = listPlanning.filter((item) => {
    const matchesTitle = item.title
      .toLowerCase()
      .includes(search.toLowerCase());

    const itemDate = new Date(item.created_at);
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;
    const matchesDate =
      (!start || itemDate >= start) && (!end || itemDate <= end);

    return matchesTitle && matchesDate;
  });

  useEffect(() => {
    setListPlanning(list);
    setFilterActive(0);
    setLoading(true);
    getPlannings();
  }, []);

  const clearFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setSearch("");
  };

  return (
    <div className="section-planning">
      <BoxPillPlanning>
        <div className="box-title">
          <BaSeHeading3 color="#005eb8" isBold>
            Empresa 2
          </BaSeHeading3>
          <BaSeHeading5 isBold>
            Meus Planejamentos ({listPlanning.length})
          </BaSeHeading5>
        </div>
        <StepComponent filterActive={filterActive} filterType={filterType} />
        <div className="search">
          <BaSeInput
            align="start"
            color="default"
            inputMode="text"
            onChange={(e) => setSearch(e)}
            placeholder="Pesquise por nome do planejamento"
            typeInput="text"
            value={search}
          />
          <button type="button" className="icon">
            <BaSeIcon
              color="#005EB8"
              description="Menu"
              name="search"
              size={24}
            />
          </button>
        </div>

        <div className="search-date">
          <p>Selecione um período</p>
          <div className="date">
            <BaSeDatePicker
              startDate={startDate}
              onStartDateChange={setStartDate}
              placeholder="Data de início"
              label="De"
            />
            <BaSeDatePicker
              startDate={endDate}
              onStartDateChange={setEndDate}
              placeholder="Data de término"
              minDate={startDate}
              label="Até"
              isDisabled={!startDate}
            />
          </div>
          {search || startDate ? (
            <div className="clear">
              <button type="button" onClick={() => clearFilter()}>
                Limpar filtro
              </button>
            </div>
          ) : null}
        </div>
        <div className="list-planing">
          {loading ? (
            <Loading />
          ) : (
            <>
              {listPlanning.length ? (
                filteredData.length ? (
                  filteredData.slice(0, planningToShow).map((review, index) => (
                    <CardStatus
                      key={index}
                      data={review}
                      idItem={id}
                      closeOpt={close}
                      callback={(e) => {
                        if (e.type === "delete") deletePlanning(e.id);
                        if (e.type === "duplicate") console.table(e);
                      }}
                    ></CardStatus>
                  ))
                ) : (
                  <div className="empty">
                    <BaSeIcon
                      color="#005EB8"
                      description="Menu"
                      name="business/archive-fill"
                      size={24}
                    />
                    <BaSeHeading5 color="#005eb8" isBold>
                      Nenhum resultado encontrado.
                    </BaSeHeading5>
                  </div>
                )
              ) : (
                <BaSeHeading5 color="#005eb8" isBold>
                  Lista vazia
                </BaSeHeading5>
              )}

              <div className="more-planning">
                {planningToShow < listPlanning.length && (
                  <button
                    type="button"
                    onClick={() => setPlanningToShow(planningToShow + count)}
                  >
                    Carregar mais planejamentos
                  </button>
                )}
              </div>

              <div className="trash-planning">
                <Accordion title="Planejamentos excluídos">
                  {listTrash.length ? (
                    listTrash.map((item, k) => (
                      <CardTrash
                        key={k}
                        idItem={id}
                        data={item}
                        callback={(id) => {
                          restorePlanning(id);
                        }}
                      />
                    ))
                  ) : (
                    <div className="list-trash" style={{ minHeight: "auto" }}>
                      <div className="title">
                        <BaSeHeading5 color="#005eb8">Lista vazia</BaSeHeading5>
                      </div>
                    </div>
                  )}
                </Accordion>
              </div>
            </>
          )}
        </div>
      </BoxPillPlanning>
    </div>
  );
};

export default MyPlanning;
