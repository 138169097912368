import "./index.scss";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { BaSeHeading4, BaSeHeading6, BaSeIcon } from "@base/react";
import ApiCMS from "../../services/cms";
import Pagination from "../../components/pagination";
import Loading from "../../components/loading";

const ApiPills = ApiCMS();

const AllPills = () => {
  const itemsPerPage = 9;
  const [pills, setPills] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = pills.slice(startIndex, endIndex);

  const getPills = () => {
    ApiPills.getAllPills(1, itemsPerPage)
      .then((response) => {
        const { data } = response;
        setPills(data.results);
        console.log("aa", currentItems);
      })
      .catch((error) => {
        console.warn("ERROR: ", error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getPills();
    setLoading(true);
  }, []);

  return (
    <div className="container">
      {loading ? (
        <Loading />
      ) : (
        <div className="all-pills-container">
          <div>
            <BaSeHeading4
              color="#005eb8"
              isBold
              className="all-pills-container__desktop-title"
            >
              Pílulas de Conhecimento
            </BaSeHeading4>
            <div className="all-pills-container__mobile">
              <Link to="/planejadora-sebrae" className="back-page">
                <BaSeIcon
                  name="angle-left"
                  color="#005eb8"
                  description="Icon"
                  size={20}
                />
                <BaSeHeading6 color="#005eb8">
                  Pílulas de Conhecimento
                </BaSeHeading6>
              </Link>
            </div>

            <div className="all-pill-cards">
              {currentItems.length &&
                currentItems.map((e) => (
                  <Link
                    to={`/pilula/${e.id}`}
                    key={e.id}
                    className="media-area"
                  >
                    {e.image ? (
                      <img src={e.image} className="image" />
                    ) : (
                      <iframe
                        src={e.video}
                        className="video"
                        style={{ pointerEvents: "none" }}
                      />
                    )}
                  </Link>
                ))}
            </div>
          </div>

          <div className="all-pills-container__pagination">
            <Pagination
              totalItems={pills.length}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AllPills;
