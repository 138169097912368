import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { decodeJWT, getToken } from "./services/utils";
import Layout from "./components/layout";
import { BaSeLoadingPage, BaSeNotFoundPage } from "@base/react";
import Home from "./pages/home";
import MyPlanning from "./pages/my-planning";
import Auth from "./pages/auth";
import Planning from "./pages/planning";
import AllPills from "./pages/all-pills";
import PillContent from "./pages/pill-content";
import PillPlanning from "./pages/pill-planning";

function App() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState(null);

  const propsDefault = {
    account,
    setAccount,
  };

  const getUserProfile = (token) => {
    const userData = decodeJWT(token);
    setAccount(userData);
  };

  useEffect(() => {
    const token = getToken();
    if (token) {
      getUserProfile(token);
    }
    setLoading(false);
  }, [location]);

  return (
    <Layout {...propsDefault}>
      {loading ? (
        <BaSeLoadingPage />
      ) : (
        <Routes>
          {!account ? (
            <Route path="/" element={<Auth {...propsDefault} />} />
          ) : (
            <>
              <Route path="/" element={<Home {...propsDefault} />} />
              <Route
                path="/:company/planejamento"
                element={<Planning {...propsDefault} />}
              />
              <Route
                path="/meus-planejamentos"
                element={<MyPlanning {...propsDefault} />}
              />
              <Route
                path="/:company/planejadora-sebrae"
                element={<PillPlanning {...propsDefault} />}
              />
              <Route
                path="/todas-pilulas-de-conhecimento"
                element={<AllPills {...propsDefault} />}
              />
              <Route
                path="/pilula/:id"
                element={<PillContent {...propsDefault} />}
              />
            </>
          )}
          <Route path="*" element={<BaSeNotFoundPage />} />
        </Routes>
      )}
    </Layout>
  );
}

export default App;
