import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { BaSeHeading3, BaSeHeading4, BaSeIcon } from "@base/react";
import "./index.scss";
import { Companies, MenuItems, ProfileItems } from "../../../mocks/menu";
import Button from "../../../components/button/button";
import logo from "../../../assets/images/logo.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/images/icons/arrow-hover-right-white.svg";
import FloatingMenu from "../../../components/floating-menu";
import Acronyms from "../../acronyms";

const baseUrl = process.env.REACT_APP_AMEI;
const realm = process.env.REACT_APP_REALM;
const client_id = process.env.REACT_APP_CLIENT_ID;
const redirect_uri = `${window.location.protocol}//${window.location.host}/`;

export default function Header({ account }) {
  const { pathname } = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuDeskOpen, setMenuDeskOpen] = useState(false);

  const actionMenu = () => {
    setMenuOpen(!menuOpen);
    document.body.style.overflow = !menuOpen ? "hidden" : "";
  };
  const logout = () => {
    const logoutURI = new URL(
      `${baseUrl}/realms/${realm}/protocol/openid-connect/logout`,
    );
    const id_token_hint = localStorage.getItem("id_token");
    const urlParams = {
      client_id,
      post_logout_redirect_uri: redirect_uri,
      id_token_hint,
    };
    for (const key of Object.keys(urlParams)) {
      logoutURI.searchParams.append(key, urlParams[key]);
    }
    localStorage.clear();
    window.location.href = logoutURI;
  };

  useEffect(() => {
    console.log("pathname header - ", pathname);
  }, [pathname]);

  return (
    <header id="header">
      <div className="container">
        <nav>
          <div className="wrapper-menu">
            <button
              className={`btn btn-menu btn-without-style ${
                menuOpen ? "active" : ""
              }`}
              onClick={() => {
                actionMenu();
              }}
            >
              <BaSeIcon
                color="#005EB8"
                description="Menu"
                name="bars"
                size={24}
              />
            </button>
            <div className={`list-menu ${menuOpen ? "open" : ""}`}>
              <div className="content">
                <div>
                  <div className="close" onClick={() => actionMenu()}></div>
                  <div className="title-menu">
                    <BaSeHeading3 isBold>
                      Planejamento
                      <ArrowIcon />
                    </BaSeHeading3>
                  </div>
                  <ul>
                    {MenuItems.map((item, k) => (
                      <li
                        key={k}
                        className={pathname === item.path ? "active" : ""}
                      >
                        <BaSeHeading4 link={item.path} isThin>
                          <span>{item.name}</span>
                          {item.icon}
                        </BaSeHeading4>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="profile-menu">
                  <div
                    className="icon-profile"
                    onClick={() => setMenuDeskOpen(!menuDeskOpen)}
                  >
                    <Acronyms nameUser={account.name} />
                  </div>
                  <ul
                    className={`floating-data ${
                      menuDeskOpen ? "profile-menu-open" : ""
                    }`}
                  >
                    <li onClick={() => console.log("open menu desktop")}>
                      <BaSeHeading4>
                        <span>{account.name}</span>
                        <span className="mid">{account.email}</span>
                      </BaSeHeading4>
                    </li>
                    {ProfileItems.map((item, k) => (
                      <li key={k}>
                        <a
                          target={item.target ? "_blank" : "_self"}
                          href={item.path}
                          rel="noreferrer"
                        >
                          <span>{item.name}</span>
                        </a>
                      </li>
                    ))}
                    <li>
                      <Button callback={() => logout()} title="Sair" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Link to="/">
            <img src={logo} className="logo" alt="logo" />
          </Link>
          <FloatingMenu
            data={Companies}
            callBack={(e) => console.table({ ID: e })}
          />
        </nav>
      </div>
    </header>
  );
}
