import "./index.scss";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { BaSeHeading5, BaSeIcon, BaSeParagraph } from "@base/react";
import moment from "moment/moment";
import ApiCMS from "../../services/cms";
import Loading from "../../components/loading";

const ApiPill = ApiCMS();

const PillContent = () => {
  const { id } = useParams();
  const [pill, setPill] = useState(null);
  const [loading, setLoading] = useState(false);

  const getPill = () => {
    setLoading(true);
    ApiPill.getPillById(id)
      .then((response) => {
        const { data } = response;
        setPill(data);
      })
      .catch((error) => {
        console.warn("ERROR: ", error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getPill();
    console.log(pill);
  }, [id]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="pill-container">
          <div className="container">
            <div className="pill-container__header">
              <div className="back-page">
                <BaSeIcon
                  name="angle-left"
                  color="#fff"
                  description="Icon"
                  size={20}
                />
                <BaSeParagraph color="#fff">
                  Pílulas de Conhecimento
                </BaSeParagraph>
              </div>
              <BaSeHeading5 isBold color="#fff">
                {pill?.title}
              </BaSeHeading5>
              <BaSeParagraph color="#fff" isThin>
                Autor:{" "}
                {pill?.modified_by.toString().length < 2
                  ? "Sebrae"
                  : pill?.modified_by}{" "}
                | Data: {moment(pill?.created_at).format("DD/MM/YYYY")}
              </BaSeParagraph>
            </div>
            {/* Mobile */}
            <div className="pill-container__body-mobile">
              <div className={`${pill?.image ? "image-container" : ""}`}>
                {pill?.image && (
                  <img src={pill?.image} alt="Imagem em destaque" />
                )}
              </div>
              <div className="text-container">
                <BaSeParagraph>{pill?.body}</BaSeParagraph>
              </div>
              <div className={`${pill?.video ? "media-container" : ""}`}>
                {pill?.video && (
                  <iframe
                    src={pill?.video}
                    width="465px"
                    height="261.95px"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                )}
              </div>
            </div>
            {/* Desktop */}
            <div className="pill-container__body-desktop">
              <div
                className={`${
                  pill?.image ? "image-container" : "media-container"
                }`}
              >
                {pill?.image ? (
                  <img src={pill?.image} alt="Imagem em destaque" />
                ) : (
                  <iframe
                    src={pill?.video}
                    width="465px"
                    height="261.95px"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                )}
              </div>
              <div className="text-container">
                <BaSeParagraph>{pill?.body}</BaSeParagraph>
              </div>
              {pill?.image && pill?.video && (
                <div className="media-container">
                  <iframe
                    src={pill?.video}
                    width="465px"
                    height="261.95px"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              )}
            </div>
          </div>
          <div className="previousAndNextPage">
            <Link
              to={`/pilula/${id}`}
              style={{ display: "flex", alignItems: "center" }}
            >
              <BaSeIcon
                name="angle-left"
                color="#005eb8"
                description="Icon"
                size={20}
              />
              <BaSeParagraph color="#005eb8" isBold>
                Conteúdo anterior
              </BaSeParagraph>
            </Link>
            <Link
              to={`/pilula/${id}`}
              style={{ display: "flex", alignItems: "center" }}
            >
              <BaSeParagraph color="#005eb8" isBold>
                Próximo conteúdo
              </BaSeParagraph>
              <BaSeIcon
                name="angle-right"
                color="#005eb8"
                description="Icon"
                size={20}
              />
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default PillContent;
