export function getToken() {
  return localStorage.getItem("token") || false;
}

export function decodeJWT(token) {
  if (token !== undefined) {
    const base64Url = token.split(".")[1] || null;
    if (base64Url) {
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(""),
      );

      return JSON.parse(jsonPayload);
    }
  }
}

export function isEqual(obj1, obj2) {
  if (obj1?.__proto__ !== obj2?.__proto__) {
    return false;
  }
  if (Array.isArray(obj1)) {
    if (obj1.length !== obj2.length) {
      return false;
    }
    for (let i in obj1) {
      if (!isEqual(obj1[i], obj2[i])) {
        return false;
      }
    }
    return true;
  }
  if (obj1 instanceof Object && typeof obj1 !== "function") {
    if (
      Object.keys(obj1)
        .filter((k) => obj1[k] !== undefined)
        .sort()
        .toString() !==
      Object.keys(obj2)
        .filter((k) => obj2[k] !== undefined)
        .sort()
        .toString()
    ) {
      return false;
    }
    for (let i in obj1) {
      if (!isEqual(obj1[i], obj2[i])) {
        return false;
      }
    }
    return true;
  }
  return obj1 === obj2;
}

export function isNotEqual(obj1, obj2) {
  return !isEqual(obj1, obj2);
}

export const converterSnakeToCamelCase = (obj) => {
  let newObj = {};

  for (let d in obj) {
    if (obj.hasOwnProperty(d)) {
      newObj[
        // eslint-disable-next-line
        d.replace(/(\_\w)/g, (k) => {
          return k[1].toUpperCase();
        })
        ] = obj[d];
    }
  }
  return newObj;
}

const camelToUnderscore = (key) => {
  const result = key.replace(/([A-Z])/g, " $1");
  return result.split(" ").join("_").toLowerCase();
}

export const converterCamelCaseToSnake = (obj) => {
  let newObj = {};

  for (let d in obj) {
    if (obj.hasOwnProperty(d)) {
      newObj[camelToUnderscore(d)] = obj[d];
    }
  }
  return newObj;
}

export function convertToSlug(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
  const to = "aaaaaeeeeeiiiiooooouuuunc------";
  let i = 0, l = from.length;
  for (; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}

export function sumValuesPercent(form) {
  return Object.values(form).reduce((acc, value) => {
    const number = typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value;
    return acc + number;
  }, 0)
}