import Requests from "./requests";

const ApiPlannings = () => {
  const instance = Requests();

  const getPlanning = () => {
    return instance.get(`/api/plans/`);
  };

  const deletePlanning = (id) => {
    return instance.delete(`/api/plans/${id}/`);
  };

  const updatePlanning = (data, id) => {
    return instance.patch(`/api/plans/${id}/`, data);
  };

  return { getPlanning, deletePlanning, updatePlanning };
};

export default ApiPlannings;
